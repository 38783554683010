.navbar {
    height: 5rem;
    background: #273248;
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}
.navname{
    display: inline-flex;
    font-family: Gentium Plus;
    
}

.fname{
    color: aquamarine;
   

}
.lname{
    color: #b8c5de;


}
.fname:hover{
    color: #b8c5de;
}
.navMenuListItem{
    margin: 1rem;
    cursor: pointer;

}

.navMenuListItem:hover{
    color: aquamarine;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid aquamarine;
}
.contactButton{
    background: aquamarine;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem ;
    border-radius: 2rem;

}
.navbconatactimg{
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: 1rem;
    
}

.active{
    color: aquamarine;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid aquamarine;
}

.mobMenu{
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: #364564;
    border-radius: 1rem;
}

.listItem{
    color: aquamarine;
    padding: 0.5rem 3rem;
    margin: 0.25rem;

}
.menuImg{
    display: none;
    object-fit: cover;
    height: 1.8rem;
}

@media screen and (max-width:720px) {
    .menuImg{
        display: flex;
       
    }
    .nevMenu{
        display: none;
    }
    .contactMe{
        display: none;
    }
    
}