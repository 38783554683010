#intro{
    height: calc(100vh-4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
   

}
.salutation{
  display: flex;
  flex-direction: row;
}

.profileGif{
    position: absolute;
    top: 15rem;
    right: 15rem;
    z-index: -1;
    object-fit: cover;
    height: 55vh;
}

.introContent{
    height: 100vh;
    width: 40vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Gentium Plus;
    color: #b8c5de;
}

.hello{
    font-size: 1.75rem;
    font-weight: 100;
    color: aquamarine;
}

.introName{
    color: aquamarine;
}

.introPara{
    font-size: medium;
    font-weight: 250;
    letter-spacing: 1px;
    
}
@media screen and (max-width:840px) {
    .profileGif{
        right: -10vw;
    }

    .introContent{
        font-size: 10vw;
    }
    .hello{
        font-size: 4.5vw;
    }

    
}

.socialbuttons {
    display: flex;
    margin-top: 30px;
    align-items: center;
    column-gap: 1rem;
 

  }
  .githubimg{
    width: 60%;
    height: auto;
  }
  .linkedinimg {
    width: 60%;
    height: auto;

  }
  .codechefimg{
    width: 70%;
    height: auto;

  }

  .githubimg:hover{
    width: 70%;
    height: auto;

  
  }
  .linkedinimg:hover{
    width: 70%;
    height: auto;
  }
  .codechefimg:hover{
    width: 80%;
    height: auto;
  }

 
