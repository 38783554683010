#competence{
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
   

}

.competenceTitle{
    font-size: 3rem;
    font-weight: 610;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
}

.competenceBlocks{
    margin: 2.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.competenceBlock{
    align-items: center;
    margin: 1rem;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: #2d3a53;
    width: 15vw;
    max-width: 80%;
}

.competenceBlockImg{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
   
    align-items: center;
}
.compText{
    font-size: 0.6rem;
    font-weight: 100;
  
  
    align-items: center;

}

