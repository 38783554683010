#contactPage{
    min-height: calc(100vh -4rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
}

.contactPageTitle{
    font-size: 3rem;
    margin-bottom: 1rem;
}
.companiesDesc{
    font-weight: 300;
    font-size: 1rem;
}

.companiesImgs{
    margin: 1rem 0;
    display: flex;

}

.compImg{
    object-fit: cover;
    width: 23%;
    min-width: 9rem;
    margin: auto;
    padding: 0.25rem;
}
#companies{
    padding: 2rem;
}

.contactDesc{
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
    

}
.name, .email, .msg{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background-color: #313e59;;

}

.submitBtn{
    background: aquamarine;
    border: none;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    border-radius: 0.5rem;
   

}

.submitBtn:hover{
    background: #44567c;;

    color: white;
    cursor: pointer;

}

